import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./TableTitle.css";
import { Dropdown, DropdownButton, Image, Modal } from "react-bootstrap";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchtable,
  setComboList,
  setCustomerPreference,
  setIsCurrentOrder,
  setIsPreferenceSet,
  setLpComboList,
  setUserRegistered,
} from "../../../Pages/HomePage/Tableslice/Tableslice";
import {
  fetchMenu,
  fetchQuickBites,
} from "../../HomePageComponent/QuickBites/QuickBiteSlice/QuickBiteSlice";
import { tables } from "../../../Pages/HomePage/Tablejson/Tablejson";
import { useChannel } from "ably/react";
import {
  addItemToCart,
  setAllPastOrders,
} from "../../../Pages/CartPage/Cartslice/Cartslice";
import { postcustomerpreference } from "../../../Pages/HomePage/action";
import { isEmpty } from "lodash";
import { getGroupedOptionsAndAddOns } from "../../../Helper/Coman";
import { Realtime } from "ably";
import { useAblyChannel } from "../../../Helper/ably/useAblyChannels";
import { toast } from "react-toastify";

function TableHeaderTitle(props) {
  const [tablenom, setTableNom] = useState();
  const [show, setShow] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [activeCategory, setActiveCategory] = useState("V");
  const [isImageShown, setIsImageShown] = useState(false);
  const { menu } = useSelector((state) => state.food);
  const { table, comboList, allCombos, customerPref, tableId, isPreferenceSet } = useSelector((state) => state?.table);
  const { cartItemsList, pastOrdersList } = useSelector((state) => state.cart);
  const { countdown, timerActive } = useSelector(state => state.timer);
  const dispatch = useDispatch();

  const location = useLocation();
  const { pathname } = location;

  const steps = 10;

  useAblyChannel("punched_sub_order", (message) => {
    const response = JSON.parse(message.data);
    if (response.order_id === table.order_id) {
      let pastOrders = [];
      const data = {
        is_punched: true,
        items: cartItemsList,
        sub_order_id: response.sub_order_id,
      };
      pastOrders = [...pastOrdersList, data];
      dispatch(setAllPastOrders(pastOrders));
      dispatch(addItemToCart([]));
      dispatch(setIsCurrentOrder(false));
      sessionStorage.setItem("cartItems", JSON.stringify([]));
      // window.location.reload()
      dispatch(fetchtable(table.table_id))
    }
  });

  useAblyChannel("order_completed", (message) => {
    console.log({message, table})
    if(message.name === table.table_id) {
      toast.success('Your order is complete. Thanks!')
      dispatch(addItemToCart([]));
      sessionStorage.setItem("cartItems", JSON.stringify([]));
      dispatch(fetchtable(table.table_id))
    }
  });
  useAblyChannel("update_sub_order", (message) => {
    console.log({message, table})
    if(message.name === table.table_id) {
      dispatch(addItemToCart([]));
      sessionStorage.setItem("cartItems", JSON.stringify([]));
      dispatch(fetchtable(table.table_id))
    }
  });
  useAblyChannel("place_sub_order", (message) => {
    console.log({message, table})
    if(message.name === table.table_id) {
      dispatch(addItemToCart([]));
      sessionStorage.setItem("cartItems", JSON.stringify([]));
      dispatch(fetchtable(table.table_id))
    }
  });


  // const { channel: punchedOrder } = useChannel("punched_sub_order", (message) => {
  //   const response = JSON.parse(message.data);
  //   if(response.order_id === table.order_id) {
  //     let pastOrders = [];
  
  //     const data = {
  //       is_punched: true,
  //       items: cartItemsList,
  //       sub_order_id: response.sub_order_id,
  //     };
  //     pastOrders = [...pastOrdersList, data];
  //     dispatch(setAllPastOrders(pastOrders));
  //     dispatch(addItemToCart([]));
  //     dispatch(setIsCurrentOrder(false))
  //     sessionStorage.setItem("cartItems", JSON.stringify([]));
  //   }
  // });
  // const { channel: placeOrder } = useChannel("place_sub_order", (message) => {
  //   console.log({ message })
  // });

  useEffect(() => {
    // dispatch(fetchtable(tables[1].table_id));
    dispatch(fetchQuickBites());
    dispatch(fetchMenu());
  }, [0]);

  useEffect(() => {
    if(tableId) {
      dispatch(fetchtable(tableId)); 
    }
  }, [tableId]);

  const handleShow = () => {
    setTableNom(table.table_id);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  function updateOrderedItemOptions(orderedItem) {
    // Update each item's options
    const updatedItems = orderedItem.items.map(item => {
      const updatedSubItems = item.items.map(subItem => {

        const { groupedOptions } = getGroupedOptionsAndAddOns(menu, subItem.item_id);

        const optionMap = new Map();
        groupedOptions.forEach(group => {
          group.itemList.forEach(option => {
            optionMap.set(option.option_id, {
              groupName: group.groupName,
              ...option
            });
          });
        });
        // Transform options array to a grouped options object
        const options = subItem.options.reduce((acc, opt) => {
          const optionDetail = optionMap.get(opt.option_id);
          if (optionDetail) {
            acc[optionDetail.groupName] = {
              option_id: optionDetail.option_id,
              price: optionDetail.price,
            };
          }
          return acc;
        }, {});
  
        return {
          ...subItem,
          options
        };
      });
      let data = {
        ...item,
        items: updatedSubItems
      } 

      const {totalOptionPrice, totalAddonsPrice} = calculateAddonAndOptionsPrice(data)
  
      data.price = data.price - (totalAddonsPrice + totalOptionPrice)
      return data;
    });
  
    // Return the updated ordered item object
    return {
      ...orderedItem,
      items: updatedItems
    };
  }
  const calculateAddonAndOptionsPrice = (basePrice) => {
    let totalOptionPrice = 0;
        let totalAddonsPrice = 0;
    basePrice?.items?.forEach(item => {
        item?.add_ons?.forEach(option => {
            totalAddonsPrice += option.price;
        });
    });

    basePrice?.items?.forEach(item => {
        Object.values(item.options)?.forEach(option => {
            totalOptionPrice += option.price;
        });
    });

    return {totalOptionPrice, totalAddonsPrice}
}
  useEffect(() => {
    if (table) {
      console.log({ table123: table })
      const localCart = JSON.parse(sessionStorage.getItem("cartItems")) || [];
      if (table?.fresh_order) {
        setShow(true);
        if (localCart) {
          dispatch(addItemToCart(localCart));
          sessionStorage.setItem("cartItems", JSON.stringify(localCart))
        } else {
          dispatch(addItemToCart([]));
          sessionStorage.setItem("cartItems", JSON.stringify([]));
        }
      }
      if (table?.diet) {
        dispatch(setCustomerPreference({ diet: table?.diet, pax: table?.pax }))
      }
      if (!table?.fresh_order) {
        dispatch(setUserRegistered(true));
        let pastOrder = [];
        let currecntOrder = [];
        for (const order of table?.order_info) {
          if (order?.is_punched) {
            pastOrder.push(order);
          } else {
            let groupWise = updateOrderedItemOptions(order)
            currecntOrder = groupWise.items;
          }
        }
        
        dispatch(setIsCurrentOrder(currecntOrder.length > 0))
        dispatch(addItemToCart([...localCart, ...currecntOrder]));
      }
    }
  }, [table]);


  useEffect(() => {
    if (table && !table?.fresh_order) {
      let pastOrder = [];
      if (table?.order_info && Array.isArray(table.order_info)) {
        for (const order of table.order_info) {
          if (order?.is_punched) {
            pastOrder.push(order);
          }
        }
      }
      dispatch(setAllPastOrders(pastOrder));
    }
  }, [table]);
  
  const handleSliderChange = (event) => {
    setCurrentStep(Number(event.target.value));
  };

  // const handleCategoryClick = (category) => {
  //   dispatch(setCustomerPreference({ diet: category, pax: currentStep }))
  //   setIsImageShown(false);
  // };

  const senddata = async () => {
    try {
      const header = {
        order_id: table?.order_id,
        pax: currentStep,
        diet: activeCategory,
      };
      const response = await postcustomerpreference(header);
      if (response?.data) {
        dispatch(setCustomerPreference({ diet: activeCategory, pax: currentStep }))
        dispatch(setIsPreferenceSet(true))
        handleClose();
        setShow(false);
      }
    } catch (error) {
      console.error("Error sending data:", error);
    }
  };

  useEffect(() => {
      if (table?.lp_combos) {
        dispatch(setLpComboList(table?.lp_combos));
      }
  }, [activeCategory, table]);

  const createCombos = (combos, diet) => {
    let comboslist = [];
    for (const combo of combos) {
      let comboItems = [];
      combo.items.map((item) => {
        const i = menu?.items?.find((i) => i.item_id === item);
        comboItems.push(i);
      });
      const data = {
        ...combo,
        diet: diet,
        items: comboItems,
      };
      comboslist.push(data);
    }
    dispatch(setComboList(comboslist));
  };

  useEffect(() => {
    if (!isEmpty(allCombos)) {
      createCombos(allCombos[activeCategory], activeCategory);
    }
  }, [allCombos, menu, activeCategory]);

  useEffect(() => {
    if (!isEmpty(customerPref)) {
      setCurrentStep(customerPref?.pax)
      setActiveCategory(customerPref?.diet)
    }
  }, [customerPref]);

console.log({ pathname })
const getSelectedImage = () => {
  switch (activeCategory) {
      case 'V':
          return <Image src="/Images/veg.svg" alt="Veg" className="dropdown-image" />;
      case 'N':
          return <Image src="/Images/nonveg.svg" alt="Non-Veg" className="dropdown-image" />;
      case 'E':
          return <Image src="/Images/egg.svg" alt="Egg" className="dropdown-image" />;
      default:
          return null;
  }
};

const handleCategoryClick = (category) => {
  dispatch(setCustomerPreference({ diet: category, pax: currentStep }))
  setIsImageShown(false)
};

const minutes = Math.floor(countdown / 60);
    const seconds = countdown % 60;

  return pathname !== '/error' ? (
    <>
      <div className={`maintitle ${props.className}`}>
        {pathname === `/cart/${tableId}` ? (
          <div className="back-main">
            <Link to={`/menu/${tableId}`} className="back-arrow">
              <Icon icon="ion:chevron-back" />
            </Link>
            {timerActive ? `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}` : ''}
          </div>
        ) : pathname === `/menu/${tableId}` ? (
          <h1 className="table-number" onClick={handleShow}>
            <Image src={props.titleicon} />
            {/* {props.title} */}
            {timerActive ? `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}` : ''}
          </h1>
        ) : pathname === `/${tableId}` ? (
          <h1 className="table-number" onClick={handleShow}>
            <Image src={props.titleicon} />
            {props.title}
          </h1>
        ) : (
          <h1 className="table-number">
            <Image src={props.titleicon} />
            {props.title}
          </h1>
        )}
        {/* <Link to={props.link}>
          <Image src={props.profileimg}></Image>
        </Link> */}
        <DropdownButton
                id="dropdown-basic-button"
                title={
                  activeCategory ? (
                        <div className="selected-option-title">
                            {getSelectedImage()}
                        </div>
                    ) : "Select Category"
                }
                className="dropdown-toggle-button- ms-auto dropdown-main"
            >
                <Dropdown.Item onClick={() => handleCategoryClick('V')}>
                    <Image src="/Images/veg.svg" alt="Veg" className="dropdown-image " />
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleCategoryClick('N')}>
                    <Image src="/Images/nonveg.svg" alt="Non-Veg" className="dropdown-image " />
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleCategoryClick('E')}>
                    <Image src="/Images/egg.svg" alt="Egg" className="dropdown-image " />
                </Dropdown.Item>
            </DropdownButton>
      </div>
      <Modal show={show} className="automodal" onHide={isPreferenceSet? handleClose : () => {}}>
        {isPreferenceSet && <Modal.Header closeButton>
        </Modal.Header>}
        <Modal.Body className="pt-5 p-3">
          <div className="guestselectmodalmain">
            <h3>Number of guests for dining?</h3>
            <div className="progress-container">
              <div className="progress-number">
                {currentStep >= 10 ? "10+" : currentStep}
              </div>
              <input
                type="range"
                min="1"
                max={steps}
                value={currentStep}
                onChange={handleSliderChange}
                className="progress-slider"
              />
            </div>
            <ul className="selectcategories">
              <li className={activeCategory === "V" ? "active" : ""}>
                <Link href="#" onClick={() => handleCategoryClick("V")}>
                  <span>
                    <Image src="/Images/veg.svg" alt="Veg" />
                  </span>
                  Veg
                </Link>
              </li>
              <li className={activeCategory === "N" ? "active" : ""}>
                <Link href="#" onClick={() => handleCategoryClick("N")}>
                  <span>
                    <Image src="/Images/nonveg.svg" alt="Non-Veg" />
                  </span>
                  Non-Veg
                </Link>
              </li>
              <li className={activeCategory === "E" ? "active" : ""}>
                <Link href="#" onClick={() => handleCategoryClick("E")}>
                  <span>
                    <Image src="/Images/egg.svg" alt="Egg" />
                  </span>
                  Egg
                </Link>
              </li>
            </ul>
            <Link href="#" className="btngreen continue" onClick={senddata}>
              Continue <Icon icon="formkit:right" width="16px" height="16px" />
            </Link>
          </div>
        </Modal.Body>
      </Modal>
    </>
  ) : '';
}
export default TableHeaderTitle;
