import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './CartItem.css';
import { Accordion, Button, Image } from 'react-bootstrap';
import PastOrder from './PastOrder';
import { placeorder, Updateplaceorder } from '../action/action';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import CartModal from './CartModal/CartModal';
import { addItemToCart } from '../../../../Pages/CartPage/Cartslice/Cartslice';
import ComboModal from './ComboModal/ComboModal';
import YouMayAlsoLike from './YouMayAlsoLike';
import { getGroupedOptionsAndAddOns } from '../../../../Helper/Coman';
import { setIsCurrentOrder } from '../../../../Pages/HomePage/Tableslice/Tableslice';
import { formatIndianCurrency } from '../../../../Helper/currencyUtil';
import { isEmpty } from 'lodash';
import { startCountdown, updateCountdown } from '../../../../Pages/CartPage/Cartslice/TimerSlice';

function CartItem() {
    const navigate = useNavigate();
    const { menu } = useSelector((state) => state?.food);
    const { table, isCurrentOrder, tableId } = useSelector((state) => state?.table);
    const [cartItems, setCartItems] = useState([]);
    const [show, setShow] = useState(false);
    const [showCombo, setShowCombo] = useState(false);
    const handleClose = () => { setShow(false); }
    const handleCloseCombo = () => { setShowCombo(false); }
    const [itemdata, setItemdata] = useState([]);
    const [comboitemdata, setComboItemdata] = useState([]);
    const [cartIndex, setCartIndex] = useState('');
    const dispatch = useDispatch()
    const { pastOrdersList, cartItemsList } = useSelector(state => state.cart)
    const { countdown, timerActive } = useSelector(state => state.timer);
    console.log({ countdown, timerActive })
    useEffect(() => {
            setCartItems(cartItemsList.toSorted((a, b) => new Date(b.time) - new Date(a.time)));
    }, [cartItemsList]);
    
    const updateCartItemsInLocalStorage = (updatedCartItems) => {
        dispatch(addItemToCart(updatedCartItems))
        sessionStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
    };

    const addquantity = (index) => {
        const updatedCartItems = cartItems.map((cartItem, idx) =>
            idx === index ? { ...cartItem, qty: cartItem.qty + 1 } : cartItem
        );
        updateCartItemsInLocalStorage(updatedCartItems);
    };

    const removequantity = (index) => {
        const updatedCartItems = cartItems.map((cartItem, idx) =>
            idx === index ? { ...cartItem, qty: cartItem.qty - 1 } : cartItem
        ).filter(cartItem => cartItem.qty > 0);
        updateCartItemsInLocalStorage(updatedCartItems);
    };

    // const calculateTotalPrice = (items) => {
    //     if (Array.isArray(items) && items.length > 0) {
    //         const total = items.reduce((itemAcc, subItem) => itemAcc + (subItem.price * subItem.qty), 0)
    //         setTotalPrice(total);
    //     } else {
    //         setTotalPrice(0); 
    //     }
    // };

    const handleCartItem = async () => {
        // if(cartItems && cartItems.length > 0){
        try {
            if(cartItems.length > 0){
                let tempCart = [...cartItems]
                let changedCartJson = tempCart?.map(item => {
                    let tempItem = { ...item }
                    const { totalOptionPrice, totalAddonsPrice } = calculateAddonAndOptionsPrice(tempItem)
                    tempItem.price = tempItem.price + totalAddonsPrice + totalOptionPrice;
                    tempItem.items = tempItem.items?.map(i => {
                        let tempi = { ...i }
                        tempi.options = Object.values(tempi.options)?.map(option => ({
                            option_id: option.option_id,
                            price: option.price
                        }))
                        return tempi
                    })
                    return tempItem
                })
                const header = {
                    table_id: table?.table_id,
                    order_id: table?.order_id,
                    items: changedCartJson,
                }
                const updatedata = {
                    order_id: table?.order_id,
                    items: changedCartJson,
                }
                if (isCurrentOrder) {
                    const response = await Updateplaceorder(updatedata)
                    if (response?.data) {
                        navigate(`/success/${tableId}`)
                        setCartItems([])
                    }
                    toast.success("Order Updated successfully!");
                } else {
                    const response = await placeorder(header)
                    if (response?.data) {
                        navigate(`/success/${tableId}`)
                        dispatch(startCountdown(300));
                        setCartItems([])
                        dispatch(setIsCurrentOrder(true))
                    }
                    toast.success("Order placed successfully!");
                }
            } else {
                toast.error("Your cart is empty!");
            }
        } catch (error) {
            console.error('Error sending data:', error);
        }
        // }else{
        //     toast.warning("Please add an item");
        // }
    }

    const handleQuickbiteClick = (quickbite) => {
        const { groupedOptions, groupedAddOns } = getGroupedOptionsAndAddOns(menu, quickbite.item_id);
        const data = {
            item_id: quickbite?.items[0]?.item_id,
            price: quickbite?.items[0]?.price,
            item_name: quickbite?.items[0]?.item_name,
            url: quickbite?.items[0]?.url,
            diet: quickbite?.items[0]?.diet,
            item_description: quickbite?.items[0]?.item_description,
            addOnsGrouped: groupedAddOns,
            optionsGrouped: groupedOptions,
        }
        setItemdata(data);
        setShow(true);
        window.history.pushState({ modal: true }, '');
    };

    const handleComboClick = (quickbite, index) => {
        let itemsdata = []
        quickbite.items.map(ele => {
            const { add_ons, options, ...rest } = ele
            const { groupedOptions, groupedAddOns } = getGroupedOptionsAndAddOns(menu, ele.item_id);
            rest.addOnsGrouped = groupedAddOns;
            rest.optionsGrouped = groupedOptions;
            itemsdata.push(rest)
        })
        const data = {
            price: quickbite.price,
            qty: quickbite.qty,
            items: itemsdata,
            discount: quickbite.discount,
        }
        console.log({ quickbite })
        setComboItemdata(data);
        setCartIndex(index)
        setShowCombo(true)
        window.history.pushState({ modal: true }, '');
    };

    const calculateAddonAndOptionsPrice = (basePrice) => {
        let totalOptionPrice = 0;
        let totalAddonsPrice = 0;
        basePrice.items?.forEach(item => {
            item.add_ons.forEach(option => {
                totalAddonsPrice += option.price;
            });
        });

        basePrice.items?.forEach(item => {
            Object.values(item.options).forEach(option => {
                totalOptionPrice += option.price;
            });
        });

        console.log({ totalOptionPrice, totalAddonsPrice })

        return { totalOptionPrice, totalAddonsPrice }
    }
    const calculateItemPrice = (cartItem, type) => {
        const basePrice = { ...cartItem };
        const { totalOptionPrice, totalAddonsPrice } = calculateAddonAndOptionsPrice(basePrice)
        let totalPrice = 0;
        let totalBasePrice = 0;

        if (type === 'combo') {
            totalPrice = ((basePrice.price + totalAddonsPrice + totalOptionPrice) - cartItem?.discount) * cartItem?.qty;
            totalBasePrice = ((basePrice.price + totalAddonsPrice + totalOptionPrice)) * cartItem?.qty;
            console.log({type, totalPrice, price: basePrice.price, totalAddonsPrice, totalOptionPrice, discount: cartItem?.discount, qty: cartItem?.qty })
        } else {
            totalPrice = (basePrice.price + totalAddonsPrice + totalOptionPrice) * cartItem?.qty;
        }
        console.log({ totalPrice })
        return {totalPrice, totalBasePrice};
    }

    const calculateCartTotal = (cart) => {
        console.log({ cart })
        let totalCartPrice = 0
        cart?.forEach(item => {
            if (['LandingPage', 'Menu'].includes(item.combo)) {
                totalCartPrice += calculateItemPrice(item, 'combo').totalPrice
            } else {
                totalCartPrice += calculateItemPrice(item, '').totalPrice
            }
        })
        return totalCartPrice
    }

    console.log({ cartItems })

    useEffect(() => {
        const handlePopState = (event) => {
            if (show || showCombo) {
                setShow(false)
                setShowCombo(false)
                event.preventDefault(); // Prevent the default back navigation
            }
        };
    
        // Add event listener for popstate
        window.addEventListener('popstate', handlePopState);
    
        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [show, showCombo]);


    return (
        <>
            <YouMayAlsoLike />
            <Accordion defaultActiveKey={['0']} alwaysOpen>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Things you have ordered right now.</Accordion.Header>
                    <Accordion.Body>
                        <ul>
                            {cartItems?.length > 0 && cartItems?.map((item, index) => item.combo === 'None' || item.combo === 'Checkout' ? (
                                <li key={index}>
                                    {console.log({ item, menu })}
                                    <div className="itemmaindetail" onClick={() => handleQuickbiteClick(item)}>
                                        <span>
                                            <Image src={item?.items[0]?.url ? item?.items[0]?.url : 'Images/combo1.png'}></Image>
                                        </span>
                                        <div className="itemsubdetail itemsubdetailWrap">
                                                        <div classname="itemsubdetailWrapImg"><Image src={item?.items[0]?.diet === 'N' ? '/Images/nonveg.svg' : item?.items[0]?.diet === 'V' ? '/Images/veg.svg' : '/Images/egg.svg'} alt="Veg" className="dietimage"></Image></div>
                                                        <div className="itemsubdetailMain">
                                                            <Link to="">{item?.items[0]?.item_name}</Link>
                                                            {item?.items[0]?.add_ons?.length > 0 && <div className="itemsubdetailULL">
                                                                <small>AddOn: </small> {item?.items[0]?.add_ons?.map((i, inx) => (
                                                                    <p >

                                                                        <span>{menu?.addOns?.find(j => j.addon_id === i.addon_id)?.addon_name}</span>
                                                                        {/* <span> - Price: {i.price}</span> */}
                                                                    </p>
                                                                ))}
                                                                </div>}
                                                            {!isEmpty(item?.items[0]?.options) && <div className="itemsubdetailULL">
                                                            <small>Options: </small> {item?.items[0]?.options && Object.entries(item?.items[0]?.options).map(([optionName, optionDetails], idx) => (
                                                                <p key={idx} className='mb-0'>
                                                                    <span>{optionName}: </span> 
                                                                    <span>{menu?.options?.find(opt => opt.option_id === optionDetails.option_id)?.option_name}</span>
                                                                    {/* <span> - Price: {optionDetails.price}</span> */}
                                                                </p>
                                                                ))}
                                                            </div>}
                                                        </div>
                                                    </div>
                                        
                                    </div>
                                    <div className="itemaddremove">
                                        <div className="addremove">
                                            <Link to="#" onClick={() => removequantity(index)}>-</Link>
                                            <span>{item.qty}</span>
                                            <Link to="#" onClick={() => addquantity(index)}>+</Link>
                                        </div>
                                        <p>₹{formatIndianCurrency(calculateItemPrice(item, '').totalPrice)}</p>
                                    </div>
                                </li>
                            ) :
                                <li className='comboBox'>
                                    {console.log({ itemc: item })}
                                    <ul onClick={() => handleComboClick(item, index)}>
                                        {item?.items?.map((i, ix) => (
                                            <li key={ix}>
                                                <div className="itemmaindetail">
                                                    <span>
                                                        <Image src={i?.url ? i?.url : 'Images/combo1.png'}></Image>
                                                    </span>
                                                    <div className="itemsubdetail itemsubdetailWrap">
                                                        <div classname="itemsubdetailWrapImg"><Image src={i.diet === 'N' ? '/Images/nonveg.svg' : i.diet === 'V' ? '/Images/veg.svg' : '/Images/egg.svg'} alt="Veg" className="dietimage"></Image></div>
                                                        <div className="itemsubdetailMain">
                                                            <Link to="">{i?.item_name}</Link>
                                                            {i?.add_ons?.length > 0 && <div className="itemsubdetailULL">
                                                                <small>AddOn: </small> {i?.add_ons?.map((i, inx) => (
                                                                    <p >

                                                                        <span>{menu?.addOns?.find(j => j.addon_id === i.addon_id)?.addon_name}</span>
                                                                        {/* <span> - Price: {i.price}</span> */}
                                                                    </p>
                                                                ))}
                                                                </div>}
                                                            {!isEmpty(i?.options) && <div className="itemsubdetailULL">
                                                            <small>Options:</small> {i?.options && Object.entries(i?.options).map(([optionName, optionDetails], idx) => (
                                                                <p key={idx} className='mb-0'>
                                                                    <span>{optionName}: </span> 
                                                                    <span>{menu?.options?.find(opt => opt.option_id === optionDetails.option_id)?.option_name}</span>
                                                                    {/* <span> - Price: {optionDetails.price}</span> */}
                                                                </p>
                                                                ))}
                                                            </div>}
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                    <div className="itemaddremove">
                                        <div className="addremove">
                                            <Link to="#" onClick={() => removequantity(index)}>-</Link>
                                            <span>{item.qty}</span>
                                            <Link to="#" onClick={() => addquantity(index)}>+</Link>
                                        </div>
                                        <p>



                                        ₹{formatIndianCurrency(calculateItemPrice(item, 'combo').totalPrice)}{" "}
                                            <i><del>₹{formatIndianCurrency(calculateItemPrice(item, 'combo').totalBasePrice)} </del></i></p>
                                        {/* <p>₹{calculateItemPrice(item, 'combo')}</p> */}
                                    </div>
                                </li>
                            )}
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <PastOrder pastOrdersList={pastOrdersList} />
            <Button disabled={isCurrentOrder && countdown === 0} className='btn-green placeorder' onClick={handleCartItem}>{!isCurrentOrder ? 'Place order' : 'Update Order'} -<span>₹{calculateCartTotal(cartItems)}</span></Button>
            <CartModal show={show} onHide={handleClose} item={itemdata} setCartItems={setCartItems} />
            <ComboModal show={showCombo} onHide={handleCloseCombo} item={comboitemdata} setCartItems={setCartItems} cartIndex={cartIndex}/>
        </>
    );
}

export default CartItem;
