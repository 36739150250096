import React, { useEffect, useState } from 'react';
import './HomePage.css';
import QuickBites from '../../Component/HomePageComponent/QuickBites/QuickBites';
import OfferBanner from '../../Component/HomePageComponent/OfferBanner/OfferBanner';
import Combos from '../../Component/HomePageComponent/Combos/Combos';
import MobileBar from '../../Component/CommonComponent/MobileBar/MobileBar';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomerPreference, setScannedTable } from './Tableslice/Tableslice';
import Search from '../../Component/CommonComponent/Search/Search';
import { isEmpty } from 'lodash';
import Title from '../../Component/CommonComponent/Title/Title';
import { useParams } from 'react-router-dom';


function HomePage() {
    const [currentStep, setCurrentStep] = useState(1);
    const [activeCategory, setActiveCategory] = useState('N');
    const [selectedFilter, setSelectedFilter] = useState([]);
    const dispatch = useDispatch();
    const { quickBites, menu } = useSelector((state) => state.food);
    const [isImageShown, setIsImageShown] = useState(false);
    const { comboList, customerPref } = useSelector((state) => state?.table);

    const {tableId} = useParams();
    
    useEffect(() => {
        if(tableId) {
            dispatch(setScannedTable(tableId))
        }
    }, [tableId])

    const toggleImage = () => {
        setIsImageShown(!isImageShown);
    };
    useEffect(() => {
        if (activeCategory) {
            const filtermenu = quickBites?.filter((item) => activeCategory === 'N' ? item?.diet === 'V' || item?.diet === 'N' || item?.diet === 'E' : activeCategory === 'E' ? item?.diet === 'V' || item?.diet === 'E' : item?.diet === 'V');
            setSelectedFilter(filtermenu)
        }
    }, [activeCategory, quickBites]);

    const handleCategoryClick = (category) => {
        dispatch(setCustomerPreference({ diet: category, pax: currentStep }))
        setIsImageShown(false)
    };

    useEffect(() => {
        if (!isEmpty(customerPref)) {
            setCurrentStep(customerPref?.pax)
            setActiveCategory(customerPref?.diet)
        }
    }, [customerPref]);

    const handleSearchchnage = (e) => {
        const serach = e.target.value;
        const filtermenu = quickBites.filter((item) => item?.item_name.toLowerCase().includes(serach.toLowerCase()) || item?.item_description.toLowerCase().includes(serach.toLowerCase()) || item?.item_subcategory.toLowerCase().includes(serach.toLowerCase()));
        setSelectedFilter(filtermenu)
    }

    return (
        <>
            <section>
                <div className="container">
                    <div className="tabledetail pb-5">
                        {/* <Search
                            selectedOption={activeCategory}
                            handleCategoryClick={handleCategoryClick}
                            handleSearchchnage={handleSearchchnage}
                            isImageShown={isImageShown}
                            toggleImage={toggleImage}
                        /> */}
                        <QuickBites menu={menu} quickBites={selectedFilter} />
                        <Title title="Restaurant Offer" className="quicktitle mb-3" />
                        <OfferBanner />
                        <Title title="Daily Offer" className="quicktitle mb-3" />
                        <OfferBanner />
                        {comboList.length > 0 ? <Combos /> : ''}
                        <MobileBar />
                    </div>
                </div>
            </section>
        </>
    );
}

export default HomePage;
