import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './CartItem.css';
import { Button, Image } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';
import { useDispatch, useSelector } from 'react-redux';
import predictCheckout from '../../../../Helper/checkout';
import Modals from '../../Modal/Modal';
import { addOnsGroupeds, getGroupedOptionsAndAddOns, optionsGroupeds } from '../../../../Helper/Coman';
import { addItemToCart } from '../../../../Pages/CartPage/Cartslice/Cartslice';

function CartItemSlider() {

  const options = {
    margin: 14,
    responsiveClass: true,
    nav: false,
    dots: false,
    smartSpeed: 500,
    autoplay: false,
    loop: true,
    items:  2,
    responsive: {
      470: {
        items: 2  ,
        margin: 10,
      }
    }

  };
  const { menu } = useSelector((state) => state?.food);
  const { customerPref } = useSelector((state) => state?.table);
  const { pastOrdersList, cartItemsList } = useSelector(state => state.cart)
  const [count, setCount] = useState(1);

  const [productsList, setProductsList] = useState([])
  const [item, setItem] = useState([]);
  const [isFilled, setIsFilled] = useState(false);
  const [show, setShow] = useState(false);
  const [flag, setFlag] = useState(null);
  const dispatch = useDispatch();


  const filterAllItemsFromCart = (data) => {
    const cartItemsForSimiller = [];
    const itemSet = new Set();
    if (menu && menu?.items) {
      data.map((element) => {
        element.items?.map((ele) => {
          if(['None', 'Checkout'].includes(ele.combo)) {
            const item = menu?.items?.find((i) => i.item_id === ele.item_id);
            if (item && !itemSet.has(item.item_id)) {
              const returmData = {
                item_id: item.item_id,
                item_subcategory: item.item_subcategory,
                qty: element.qty ? element.qty : ele.qty,
              };
              cartItemsForSimiller.push(returmData);
              itemSet.add(item.item_id);
            }
          } else {
            ele?.items?.map(i => {
              const item = menu?.items?.find((i) => i.item_id === i.item_id);
              if (item && !itemSet.has(item.item_id)) {
                const returmData = {
                  item_id: item.item_id,
                  item_subcategory: item.item_subcategory,
                  qty: element.qty ? element.qty : i.qty,
                };
                cartItemsForSimiller.push(returmData);
                itemSet.add(item.item_id);
              }
            })
          }
        });
      });
      const youMayLike = predictCheckout(
        menu,
        customerPref?.pax,
        cartItemsForSimiller,
        customerPref?.diet
      );
      const predictedItems = youMayLike.map((ele) => {
        const item = menu.items.find((i) => i.item_id === ele);
        return item;
      });
      setProductsList(predictedItems);
    }
  };
  useEffect(() => {
    let data = []
    if (pastOrdersList?.length > 0) {
      data = [...data, ...pastOrdersList]
    }
    if (cartItemsList?.length > 0) {
      data = [...data, ...cartItemsList]
    }
    filterAllItemsFromCart(data)
  }, [pastOrdersList, cartItemsList, menu, customerPref])

  const handleClose = () => setShow(false);
  const handleCardSlide = (quickbite) => {
    // console.log({ quickbite })
    setFlag('Checkout');
    const { groupedOptions, groupedAddOns } = getGroupedOptionsAndAddOns(menu, quickbite.item_id);
    console.log({ groupedOptions, groupedAddOns })
    if(groupedAddOns.length > 0 || groupedOptions.length > 0) {
      const data = {
        item_id: quickbite.item_id,
        price: quickbite.price,
        item_name: quickbite.item_name,
        item_description: quickbite.item_description,
        url: quickbite.url,
        diet: quickbite.diet,
        addOnsGrouped: groupedAddOns,
        optionsGrouped: groupedOptions,
      }
      setItem(data);
      setShow(true);
    } else {
      const selectedItem = {
        item_id: quickbite.item_id,
        combo: 'Checkout',
        discount: 0,
        price: quickbite.price,
        qty: count,
        // ite: item?.diet,
        items: [
          {
            url: quickbite?.url,
            diet: quickbite?.diet,
            item_name: quickbite.item_name,
            item_description: quickbite.item_description,
            item_id: quickbite.item_id,
            price: item.price,
            add_ons: [],
            options: [],
          },
        ],
      };

    //   console.log({ item, option, allOptionsSelected, selectedItem });
    // return
    let cartItems = [...(cartItemsList || [])];
    const existingItemIndex = cartItems.findIndex(
      (cartItem) => cartItem.item_id === selectedItem.item_id
      );
  
      // console.log({ selectedItem, existingItemIndex, cartItems })
      // return
      if (existingItemIndex >= 0) {
        // console.log({ cartItems: cartItems[existingItemIndex].qty, qty: selectedItem.qty })
        
        const updatedItem = { ...cartItems[existingItemIndex] };
        updatedItem.qty += selectedItem.qty;
  
        // Update the cart with the new item data
        cartItems[existingItemIndex] = updatedItem;
        // cartItems[existingItemIndex].qty += selectedItem.qty;
        // cartItems[existingItemIndex].price += selectedItem.price;
      } else {
        cartItems.push(selectedItem);
      }
  
      sessionStorage.setItem("cartItems", JSON.stringify(cartItems));
      dispatch(addItemToCart(cartItems));
  
    }

  };

  console.log({ item })

  return (
    <div className="Combomain" >
      {productsList.length > 0 && <OwlCarousel className="owl-theme mb-3" {...options}>
        {productsList.map((ele, index) => (
          <div className="item" key={index}>
            <div className="combodetail combodetail-slider">
              <ul className='saladimgs gap-1 mb-0'>
                <li ><Image src={ele?.url ? ele?.url : 'Images/manchurianimg.png'}></Image></li>
              </ul>
              <div className="combosubdetail">
                {console.log({ ele })}
                <h3>{ele.item_name}</h3>
                {/* ₹{ele.price} */}
              </div>
            </div>
                <div className="comboprice combopriceMargin">
                  <Link onClick={() => handleCardSlide(ele)}>+ ADD</Link>
                  {/* <p>₹{ele.price} </p> */}
                </div>
          </div>
        ))}
      </OwlCarousel>}
      <Modals
        item={item}
        show={show}
        onHide={handleClose}
        handleIconClick={() => setIsFilled(!isFilled)}
        isFilled={isFilled}
        flag={flag}
      />
    </div>
  );
}

export default CartItemSlider;
