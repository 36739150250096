// src/redux/slices/countdownSlice.js
import { createSlice } from '@reduxjs/toolkit';

const loadCountdownFromStorage = () => {
    const savedCountdown = localStorage.getItem('countdown');
    const savedTimestamp = localStorage.getItem('countdownTimestamp');
    if (savedCountdown && savedTimestamp) {
        const elapsedTime = Math.floor((Date.now() - savedTimestamp) / 1000); // Get the elapsed time in seconds
        const remainingTime = savedCountdown - elapsedTime;
        return remainingTime > 0 ? remainingTime : 0; // Ensure countdown doesn't go below 0
    }
    return 0; // Default to 0 if nothing is saved
};

const initialState = {
    countdown: loadCountdownFromStorage(), // Load the saved countdown or default to 0
    timerActive: loadCountdownFromStorage() > 0, // Start the timer if countdown > 0
};

const timerSlice = createSlice({
    name: 'timer',
    initialState,
    reducers: {
        startCountdown: (state, action) => {
            state.countdown = action.payload; // Set the initial countdown (e.g., 300 seconds for 5 minutes)
            state.timerActive = true; // Start the timer
            localStorage.setItem('countdown', action.payload); // Save updated countdown
            localStorage.setItem('countdownTimestamp', Date.now()); // Save updated timestamp
        },
        updateCountdown: (state, action) => {
            state.countdown = action.payload; // Update the countdown value
            localStorage.setItem('countdown', action.payload); // Save updated countdown
            localStorage.setItem('countdownTimestamp', Date.now()); // Save updated timestamp
        },
        stopCountdown: (state) => {
            state.timerActive = false; // Stop the countdown
            state.countdown = 0; // Reset countdown
            localStorage.removeItem('countdown'); // Remove countdown from localStorage
            localStorage.removeItem('countdownTimestamp'); // Remove timestamp from localStorage
        },
    },
});

export const { startCountdown, updateCountdown, stopCountdown } = timerSlice.actions;
export default timerSlice.reducer;