import React, { useEffect, useState } from 'react';
import MobileBar from '../../Component/CommonComponent/MobileBar/MobileBar';
import './InfoPage.css'

function InfoPage() {
    return (
        <div className='info-container'>
            <div>Restaurants Info</div>
            <MobileBar />
        </div>
    );
}

export default InfoPage;
