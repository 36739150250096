import React from 'react';
import './Search.css';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
function Search({ selectedOption, handleSearchchnage, searchTerm }) {
    return (
        <div className={`searchmain`}>
            <i><Image src='/Images/searchicon.svg' alt="Search Icon" /></i>
            <input type="text" placeholder='Search' value={searchTerm} onChange={(event) => handleSearchchnage(event.target.value.toLowerCase())} />
            {searchTerm && <Link to="#" onClick={() => handleSearchchnage('')}><Icon icon="zondicons:close-solid" /></Link>}
        </div>
    );
}
export default Search;