import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './MobileBar.css';
import { Icon } from '@iconify/react/dist/iconify.js';
import { Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';

function MobileBar() {
    const location = useLocation()
    const [activeLink, setActiveLink] = useState(location.pathname);
    const { cartItemsList } = useSelector((state) => state.cart);
    const { isRegistered } = useSelector((state) => state.table);
    const { tableId } = useSelector((state) => state.table);

    useEffect(() => {
        console.log({ location })
        setActiveLink(location.pathname);
    }, [location.pathname])

    // Handler to set the active link
    // const handleSetActive = (path) => {
    //     setActiveLink(path);
    // };

    return (
        <ul className='mobilebar'>
            <li className={activeLink === `/${tableId}` ? 'active' : ''}>
                <Link to={`/${tableId}`} onClick={() => setActiveLink(`/${tableId}`)}>
                    <span><Icon icon="majesticons:home" /></span>
                    <p>Home</p>
                </Link>
            </li>
            {/* <li className={activeLink === '/favourite' ? 'active' : ''}>
                <Link to="/favourite" onClick={() => setActiveLink('/favourite')}>
                    <span><Icon icon="mdi:favourite" /></span>
                    <p>Favourite</p>
                </Link>
            </li> */}
            <li className={activeLink === `/menu/${tableId}` ? 'active' : ''}>
                <Link to={`/menu/${tableId}`} onClick={() => setActiveLink(`/menu/${tableId}`)}>
                    <span><Image src='/Images/cart.svg' /></span>
                    <p>Menu</p>
                </Link>
            </li>
            <li className={activeLink === `/cart/${tableId}` ? 'active' : ''} style={{position: 'relative'}}>
                <Link to={isRegistered ? `/cart/${tableId}` : `/signUp/${tableId}`} onClick={() => setActiveLink(`/cart/${tableId}`)} style={{position: 'relative'}}>
                    {/* <Link to={"/cart"} onClick={() => setActiveLink('/cart')}> */}
                    <span><Icon icon="mdi:cart" /></span>
                    <p>Cart</p><i>{cartItemsList?.length}</i>
                </Link>
            </li>
            <li className={activeLink === `/info/${tableId}` ? 'active' : ''} style={{position: 'relative'}}>
                <Link to={`/info/${tableId}`} onClick={() => setActiveLink(`/info/${tableId}`)} style={{position: 'relative'}}>
                    {/* <Link to={"/cart"} onClick={() => setActiveLink('/cart')}> */}
                    <span><Icon icon="mdi:cart" /></span>
                    <p>Info</p>
                </Link>
            </li>
        </ul>
    );
}

export default MobileBar;
