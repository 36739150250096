import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Image, Row } from 'react-bootstrap';
import { Icon } from '@iconify/react/dist/iconify.js';
import Title from '../../CommonComponent/Title/Title';
import CombosSlider from '../Combos/CombosSlider';
import './ItemDetails.css';
import Loader from '../../CommonComponent/Loader/Loader';
import { useSelector } from 'react-redux';
import Modals from '../../CommonComponent/Modal/Modal';
import { getGroupedOptionsAndAddOns } from '../../../Helper/Coman';
import confetti from "https://esm.run/canvas-confetti@1";
import predictMenu from '../../../Helper/menuCombo';
import { isEmpty } from 'lodash'
import { formatIndianCurrency } from '../../../Helper/currencyUtil';

function ItemDetails({ items, selectedCategory, activePref }) {
    const { menu } = useSelector((state) => state.food);
    const { cartItemsList } = useSelector((state) => state.cart);
    const { customerPref } = useSelector((state) => state?.table);
    const [activeSlider, setActiveSlider] = useState({});
    const [loading, setLoading] = useState(true);
    const sliderRefs = useRef({});
    const [activeBgGreen, setActiveBgGreen] = useState(null);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [item, setItem] = useState([]);
    const [isFilled, setIsFilled] = useState(false);
    const [flag, setFlag] = useState(null);
    const [menuComboList, setMenuComboList] = useState([])
    const [expandedItem, setExpandedItem] = useState(null);
    const [activeCategory, setActiveCategory] = useState('V');
    // Function to handle the click and toggle the slider visibility
    const handleViewCombosClick = async (itemKey, e, item) => {
        e.preventDefault();

        let cartForCombo = [];
        cartItemsList?.map(j => {
            j.items.map(i => {
                const menuItem = menu.items.find((k) => k.item_id === i.item_id);
                const data = {
                    item_id: menuItem.item_id,
                    item_name: menuItem.item_name,
                    item_category: menuItem?.item_category,
                    item_subcategory: menuItem?.item_subcategory,
                    qty: j.qty,
                    price: j.price,
                    diet: menuItem?.diet,
                    is_available: menuItem?.is_available,
                    is_quickbite: menuItem?.is_quickbite,
                    flavify_category: menuItem?.flavify_category,
                }
                cartForCombo.push(data)
            })
        });

        let data = await predictMenu(menu, cartForCombo, customerPref.diet, item)
        data.diet = item.diet
        setMenuComboList(data)

        setActiveSlider(prevState => ({
            [itemKey]: !prevState[itemKey]
        }));
        setActiveBgGreen(prevState => (prevState === itemKey ? null : itemKey));
        if (!([itemKey] in activeSlider)) {
            confetti({
                particleCount: 150,
                spread: 60
            });
        } else {
            setMenuComboList(data)
        }
    };

    useEffect(() => {
        if (items) {
            setActiveSlider({});
            setActiveBgGreen(null);
            setLoading(false);
            applyFiltersScroll();
        }
    }, [items]);
    useEffect(() => {
        if (activePref) {
            setActiveSlider({});
            setActiveBgGreen(null);
            setActiveCategory(activePref)
        }
    }, [activePref]);

    const handleQuickbiteClick = (quickbite) => {
        setFlag('None');
        const { groupedOptions, groupedAddOns } = getGroupedOptionsAndAddOns(menu, quickbite.item_id);

        const data = {
            item_id: quickbite.item_id,
            price: quickbite.price,
            item_name: quickbite.item_name,
            url: quickbite.url,
            diet: quickbite.diet,
            item_description: quickbite.item_description,
            addOnsGrouped: groupedAddOns,
            optionsGrouped: groupedOptions,
        }
        setItem(data);
        setShow(true);
        window.history.pushState({ modal: true }, '');
    };

    useEffect(() => {
        const handlePopState = (event) => {
            if (show) {
                setShow(false)
                event.preventDefault(); // Prevent the default back navigation
            }
        };
    
        // Add event listener for popstate
        window.addEventListener('popstate', handlePopState);
    
        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [show]);

    const handleExpandToggle = (index) => {
        setExpandedItem(expandedItem === index ? null : index); // Collapse if same index
    };

    const scrollContainerRef = useRef(null);

  const applyFiltersScroll = () => {
    // Scroll to the top of the container
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth', // Optional: add smooth scrolling
      });
    }
  };

    return (
        <>
            <div ref={scrollContainerRef} className="itemdetails mb-5">
                <Title title={selectedCategory?.item_name ? selectedCategory?.item_name : selectedCategory} className="quicktitle mb-3" />
                {loading ? (
                    <div><Loader /></div>
                ) : (
                    items?.length > 0 && items.map((item, index) => (
                        <div key={index} className={`bg-white ${activeBgGreen === index ? 'bg-green' : 'bg-white'}`}>
                            <Row onClick={() => handleQuickbiteClick(item)}>
                                <Col lg={8}>
                                    <div className="itemtitle mb-0">
                                        <div className="ratingmain">
                                            <h3>{item.item_name}</h3> <span><Image src={item.diet === 'N' ? '/Images/nonveg.svg' : item.diet === 'V' ? '/Images/veg.svg' : '/Images/egg.svg'} alt="Veg"></Image></span>
                                        </div>
                                        {/* <h3>{item.item_name}</h3> */}
                                        <h4>₹{formatIndianCurrency(item.price)}</h4>
                                        <h4>{item.item_subcategory}</h4>
                                        <p className={`descrioton ${expandedItem === index ? 'expanded' : 'collapsed'}`}>
                                            {item.item_description}
                                        </p>
                                        {item.item_description
                                            ?
                                            <Link className="readmore" onClick={() => handleExpandToggle(index)}>
                                                {expandedItem === index ? 'Read Less' : 'Read More...'}
                                            </Link>
                                            : ""
                                        }
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="itemaddimg">
                                        <Image src={item?.url ? item?.url : '/Images/itemimg.png'} alt="Item"></Image>
                                        <Link to="#" onClick={() => handleQuickbiteClick(item)}><Icon icon="charm:plus" width="16px" height="16px" /> ADD</Link>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={8}>
                                    {["Soups & Salads", "Starters", "Main Course", "Desserts", "Beverages"].includes(item?.item_category) && ["Stars", "Puzzles"].includes(item?.flavify_category) && <Link
                                        to="#"
                                        className='viewcombomain mb-3 mw-100'
                                        onClick={(e) => handleViewCombosClick(index, e, item)}
                                    >
                                        Combos <Icon icon="iconamoon:arrow-down-2-light" width="16px" height="16px" />
                                    </Link>}
                                </Col>
                            </Row>
                            {activeSlider[index] && (
                                <div ref={(el) => (sliderRefs.current[index] = el)}>
                                    <CombosSlider comboList={menuComboList} dietPref={activeCategory} />
                                </div>
                            )}
                        </div>
                    ))
                )}
            </div>
            <Modals
                item={item}
                show={show}
                onHide={handleClose}
                handleIconClick={() => setIsFilled(!isFilled)}
                isFilled={isFilled}
                flag={flag}
            />
        </>
    );
}

export default ItemDetails;
