import React, { useEffect, useRef, useState } from 'react';
import './MenuPage.css';
import MobileBar from '../../Component/CommonComponent/MobileBar/MobileBar';
import ItemDetails from '../../Component/MenuPageComponent/ItemDetails/ItemDetails';
import { useDispatch, useSelector } from 'react-redux';
import Search from '../../Component/CommonComponent/Search/Search';
import Carousel from '../../Component/CommonComponent/OwlCarousel/OwlCarousel';
import { isEmpty } from 'lodash';
import { setCustomerPreference, setScannedTable } from '../HomePage/Tableslice/Tableslice';
import { useParams } from 'react-router-dom';

function MenuPage() {
  const [currentStep, setCurrentStep] = useState(1);
  const { menu, categories } = useSelector((state) => state.food);
  const [activePref, setActivePref] = useState('N');
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [filteredItems, setFilteredItems] = useState([]);
  const { customerPref, table } = useSelector((state) => state?.table);
  const [isImageShown, setIsImageShown] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const dispatch = useDispatch()

  const {tableId} = useParams();
  const scrollContainerRef = useRef(null);

    useEffect(() => {
        if(tableId) {
            dispatch(setScannedTable(tableId))
        }
    }, [tableId])

  const toggleImage = () => {
    setIsImageShown(!isImageShown);
  };

  useEffect(() => {
    if (selectedCategory && activePref) {
      filterMenu(menu, selectedCategory, activePref, '');
    }
  }, [table, selectedCategory, activePref, menu]);

  const handleQuickbiteClick = (category) => {
    // event.stopPropagation();
    setSelectedCategory(category);
    setSearchTerm('')
    filterMenu(menu, category, activePref, '')
  };

  function filterMenu(data, selectedCategory, dietPreference, searchQuery) {
    let filteredItems = data?.items?.filter(i => i.is_available);
    if (searchQuery) {
      const lowerCaseQuery = searchQuery.toLowerCase();
      filteredItems = filteredItems.filter(
        (item) =>
          item.item_subcategory.toLowerCase().includes(lowerCaseQuery) ||
          item.item_name.toLowerCase().includes(lowerCaseQuery) ||
          (item.item_description &&
            item.item_description.toLowerCase().includes(lowerCaseQuery))
      );
      setFilteredItems(filteredItems);
    } else {
      if (filteredItems?.length > 0) {
        const categoryList = [
          "Soups & Salads",
          "Starters",
          "Main Course",
          "Desserts",
          "Beverages",
        ];
        const flavifyCategoryList = ["Stars", "Puzzles"];
    
        // Filter by selected category if not 'All'
        if (selectedCategory !== "All") {
          filteredItems = filteredItems.filter(
            (item) => item.item_subcategory === selectedCategory
          );
        }
    
        // Filter by diet preference
        if (dietPreference === "V") {
          filteredItems = filteredItems.filter((item) => item.diet === "V");
        } else if (dietPreference === "E") {
          filteredItems = filteredItems.filter(
            (item) => item.diet === "V" || item.diet === "E"
          );
        }
    
        // Apply search filter
        if (searchQuery) {
          const lowerCaseQuery = searchQuery.toLowerCase();
          filteredItems = filteredItems.filter(
            (item) =>
              item.item_subcategory.toLowerCase().includes(lowerCaseQuery) ||
              item.item_name.toLowerCase().includes(lowerCaseQuery) ||
              (item.item_description &&
                item.item_description.toLowerCase().includes(lowerCaseQuery))
          );
        }
    
        // Separate prioritized and other items
        const prioritizedItems = filteredItems.filter(
          (item) =>
            categoryList.includes(item.item_category) &&
            flavifyCategoryList.includes(item.flavify_category)
        );
        const otherItems = filteredItems.filter(
          (item) =>
            !categoryList.includes(item.item_category) ||
            !flavifyCategoryList.includes(item.flavify_category)
        );
    
        // Define sort order for categories
        const sortOrder = [
          "soups & salads",
          "appetizers & mezzes",
          "shawarma, falafel, burgers",
          "turkish pide pizza",
          "kebabs",
          "jumbo group meal combos",
          "pasta",
          "main course",
          "biryani & bread",
          "desserts",
          "beverages",
          "alcoholic",
        ];
  
        // Sort items by category order and diet preference
        const dietOrder = ["N", "E", "V"]; // Assuming 'N' means no diet preference
    
        const sortFunction = (a, b) => {
          // Sort by category order first
          const categoryComparison =
            sortOrder.indexOf(a.item_subcategory.toLowerCase()) -
            sortOrder.indexOf(b.item_subcategory.toLowerCase());
          if (categoryComparison !== 0) return categoryComparison;
    
          // Within the same category, sort by diet preference
          const dietComparison =
            dietOrder.indexOf(a.diet) - dietOrder.indexOf(b.diet);
          return dietComparison;
        };
        
        // Apply sorting
        prioritizedItems.sort(sortFunction);
        otherItems.sort(sortFunction);
        
        console.log({prioritizedItems, otherItems})

        const finalResult = [...prioritizedItems, ...otherItems];
        setFilteredItems(finalResult);
      }
    }
  }
  console.log({ filteredItems })
  

  useEffect(() => {
    if (!isEmpty(customerPref)) {
      setCurrentStep(customerPref?.pax)
      setActivePref(customerPref?.diet)
    }
  }, [customerPref]);

  const handleCategoryClick = (pref) => {
    dispatch(setCustomerPreference({ diet: pref, pax: currentStep }))
    setIsImageShown(false)
  };

  const handleSearchChange = (searchtext) => {
    // const searchtext = event.target.value ? event.target.value.toLowerCase() : '';
    setSearchTerm(searchtext)
    filterMenu(menu, selectedCategory, activePref, searchtext)
  };

  // console.log({ categories: categories.map(item => item.item_name)})
  const [isAtBottom, setIsAtBottom] = useState(false);
  let lastScrollTop = 0;
  const handleScroll = () => {
    const container = scrollContainerRef.current;

    if (container) {
      const scrollTop = container.scrollTop;

    // Determine scroll direction
    if (scrollTop > lastScrollTop) {
      // Scrolling down
      setIsAtBottom(false);
    } else {
      // Scrolling up
      setIsAtBottom(true);
    }

    // Update the last scroll position
    lastScrollTop = scrollTop;
    }
  };

  useEffect(() => {
    const container = scrollContainerRef.current;

    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);
  return (
    <>
      <section>
        <div className="container">
          <div className="tabledetail">
            <Search
              selectedOption={activePref}
              handleCategoryClick={handleCategoryClick}
              handleSearchchnage={handleSearchChange}
              isImageShown={isImageShown}
              toggleImage={toggleImage}
              searchTerm={searchTerm}
            />
            <div className={`menunpage-scroll ${isAtBottom ? "bottom-class" : ""}`} ref={scrollContainerRef}>
              {categories.length > 0 && <Carousel items={categories} handleQuickbiteClick={handleQuickbiteClick} isMenu={true} />}
              <p className='para'>Choosing combo offers usually saves upto 20% on the total bill order</p>
            <ItemDetails items={filteredItems} selectedCategory={selectedCategory} activePref={activePref} />
            </div>
            <MobileBar />
          </div>
        </div>
      </section>
    </>
  );
}

export default MenuPage;
