import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import './App.css';
import HomePage from './Pages/HomePage/HomePage';
import MenuPage from './Pages/MenuPage/MenuPage';
import SignUp from './Pages/SignUp/SignUp';
import SuccessPage from './Pages/SuccessPage/SuccessPage';
import CartPage from './Pages/CartPage/CartPage';
import TableHeaderTitle from './Component/CommonComponent/TableTitle/TableHeaderTitle';
import { useDispatch, useSelector } from 'react-redux';
import GeoFenceWrapper from './Helper/GeoFenceWrapper';
import Error from './Component/Error/Error';
import InfoPage from './Pages/InfoPage/InfoPage';
import { stopCountdown, updateCountdown } from './Pages/CartPage/Cartslice/TimerSlice';
function App() {
  const { table } = useSelector((state) => state?.table);
  const dispatch = useDispatch();
  const targetLocation = {
    latitude: 28.468231025867823, // Replace with your target latitude
    longitude: 77.08309229997275 // Replace with your target longitude
  };
  const { countdown, timerActive } = useSelector(state => state.timer);
  console.log({ countdown, timerActive })

    // Start a background timer if the countdown is active
    useEffect(() => {
        if (timerActive && countdown > 0) {
            const interval = setInterval(() => {
                dispatch(updateCountdown(countdown - 1)); // Update countdown every second
            }, 1000);

            // Cleanup interval on unmount or when countdown is stopped
            return () => clearInterval(interval);
        }
    }, [dispatch, countdown, timerActive]);
    useEffect(() => {
      if(countdown === 0) {
        dispatch(stopCountdown())
      }
    }, [dispatch, countdown])

  return (
    <>
      <TableHeaderTitle titleicon="/Images/table.svg" title={`Table Number : ${table?.table_number ? table?.table_number : '' }`} className="d-flex" profileimg="/Images/profile.svg" link="#"/>
      {/* <GeoFenceWrapper targetLocation={targetLocation}> */}
        <Routes>
          <Route path="/:tableId" element={<HomePage />} /> 
          <Route path="/menu/:tableId" element={<MenuPage />} />
          <Route path="/signUp/:tableId" element={<SignUp />} /> 
          <Route path="/cart/:tableId" element={<CartPage />} /> 
          <Route path="/success/:tableId" element={<SuccessPage />} /> 
          <Route path="/error" element={<Error/>} />
          <Route path="/info/:tableId" element={<InfoPage/>} />
        </Routes>
      {/* </GeoFenceWrapper> */}
    </>
  );
}

export default App;
